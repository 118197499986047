#header {
  // min-height: 130px;
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 20px;

  @media only screen and (max-width: 1200px) {
    padding-top: 0;
    padding-bottom: 0;
    // min-height: 100px;
  }

  @media only screen and (max-width: 767px) {
    display: block;
  }

  #logo {

    @media only screen and (max-width: 1200px) {
      margin: 10px 0 20px 0;
    }
    
    a {
      img {
        float: left;
        width: 450px;

        @media only screen and (max-width: 960px) {
          float: none;
        }
      }
    }
  }

  #tagline {
    color: #888;
    border-left: 1px solid #e9e9e9;
    margin: 0 0 0 20px;
    padding: 5px 0 5px 20px;
    float: left;
    display: none;

    @media only screen and (max-width: 960px) {
      border-left:none;
      float: none;
      display: none;
      padding-left: 0;
      margin-left: 0;
      margin-top: 2px;
    }
  }
  .header-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 35px;
    text-align: right;

    @media only screen and (max-width: 767px) {
      margin-top: 0;
    }

    .header-links {
      margin: 0;
      text-align: right;
      display: flex;
      align-items: center;
  
      @media only screen and (max-width: 767px) {
        padding-left: 0;
        text-align: left;
        display: block;
      }
  
      li {
        display: inline-block;
        margin-right: 4px;
        text-align: center;

        @media only screen and (max-width: 767px) {
          display: block;
          width: 100%;
          margin-right: 0;
          text-align: center;
        }
  
        a {
          display: inline-block;
          transition: all 0.3s ease-in-out;
          position: relative;
          overflow: hidden;
          background: #fff;
        }

        &:last-child {
          margin-left: 15px;

          @media only screen and (max-width: 767px) {
            margin-left: 0;
          }

          a {
            background: #10819b;
            transition: all 0.3s ease-in-out;
            position: relative;
            overflow: hidden;
            color: white;
            padding: 10px;
            margin-top: 0;

            @media only screen and (max-width: 767px) {
              width: 100%;
              margin-top: 15px;
            }


            &:hover,
            &:focus {
              background: #303030;
              text-decoration: none;
            }
          }
        }
      }
    }
  
    .contact-details {
      float: right;
      margin-top: 20px;
      display: block;
  
      @media only screen and (max-width: 767px) {
        float: none;
      }
  
      ul {
        padding-left: 0;
        
        li {
          display: inline;
          padding-left: 10px;
          margin-left: 10px;
          border-left: 1px solid #e9e9e9;
    
          &:first-child {
            border: none;
            margin-left: 0;
            padding:0
          }
        }
      }
  
      i {
        margin-right: 5px; 
        margin-top: 3px;
      }
  
      a {
        color: #707070;
  
        &:hover {
          color: #888;
        }
      }
    }

  }
  

}