.column {
  img {
    height: auto;
    max-width: 100%;
  }

  &.google-map img {
		max-width: none;
	}
}

img, 
object, 
video {
  height: auto;
  display:block;
}

img {
  width: auto;
  border: 0;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
}

.embed {
  position: relative;
  padding: 0px;
  padding-bottom: 56.25%; /* 16/9 ratio */
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
  }
}

.image-left {
  float: left;
  margin: 0 15px 8px 0;
}

.image-right {
  float:right;
  margin: 0 0 8px 15px;

}
  
.image-right,
.image-left {
  padding: 4px;
  border: 1px solid #ddd;
  max-width: 100%;
  box-shadow:  0px 1px 1px 0px rgba(180, 180, 180, 0.1);
}

.padding-left { 
  padding-left: 20px; 
}

.padding-right { 
  padding-right: 20px; 
}
