.button,
input[type='submit'] {
  color: #fff;  
  background: $blue-stone;
	padding: 9px 12px;
	display: inline-block;
	border: 0px;
	font-family: "Open Sans", sans-serif;
	font-weight: bold;
	cursor: pointer;
	width: auto;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:visited {
    background: $blue-lagoon;
  }
  
}

.button {

  &.primary {
    background: $blue-stone;

    &:hover,
    &:focus,
    &:visited {
      background: $blue-lagoon;
    }

    &.light {
      background: $blue-lagoon; 
  
      &:hover,
      &:focus,
      &:visited {
        background: $java;
      }     
    }

    &.lighter {
      background: $java; 
      
      &:hover,
      &:focus,
      &:visited {
        background: $blue-lagoon;
      }  
    }
  }

  &.secondary {
    background: $olive;
      
    &:hover,
    &:focus,
    &:visited {
      background: $olive-light;
    }  

    &.light {
      background: $olive-light;  
      
      &:hover,
      &:focus,
      &:visited {
        background: $lemon-ginger;
      }  
    }

    &.lighter {
      background: $lemon-ginger;    
      
      &:hover,
      &:focus,
      &:visited {
        background: $olive-light;
      }  
    }
  }

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  &.medium {
    padding: 11px 17px; 
    font-size: 14px;
  }

}