#wrapper {
  background: #fff;
  // padding: 40px 50px 50px 50px;
  margin: 50px auto;
  width: 80%;
  max-width: 1280px;

  @media only screen and (max-width: 1200px) {
    margin: 0 auto;
		padding: 25px 0px 0px 0px;
		-webkit-box-shadow: none;
		box-shadow: none;
		width: 100%;
  }    

  @media only screen and (max-width: 767px) {
		padding: 5px 0px 0px 0px;

  }   
}

.container {
  padding: 0 50px;

  // @media only screen and (max-width: 1200px) {
  //   padding: 0 50px;
  // }

  @media only screen and (min-width: 768px) and (max-width: 960px) {
    padding: 0 20px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 150px;
  }

  @media only screen and (max-width: 680px) {
    padding: 0 40px;
  }
}

.main-content {
  min-height: 400px;
  margin-bottom: 35px;
}

.two-column {
  display: flex;

  @media only screen and (max-width: 486px) {
    flex-direction: column;
  }

  .col {
    flex: 1;
  }
}

.home-content ul {
  padding-left: 0;
}