.acc-trigger {
	cursor: pointer;
	border: 1px solid $oliver;
	outline: none;
	color: $olive-light;
	margin: 10px 0;
	display: block;
	background: $citrine-white;
	-webkit-box-shadow:  0px 1px 1px 0px rgba(180, 180, 180, 0.1);
	box-shadow:  0px 1px 1px 0px rgba(180, 180, 180, 0.1);
	position: relative;
  overflow: hidden;
  
  a {
    display: block;
    color: $verdun-green;
    outline: none;
    padding: 13px 15px;
    text-decoration: none;
    font-weight: bold;
    max-width: 80%;

    &:hover,
    &:focus {
      color: $olive;
    }

    &::before {
      content: "";
      font-family: "FontAwesome";
      font-size: 14px;
      font-weight: normal;
      color: $olive;
      float: right;
      top: 0;
      right: 0;
      position: absolute;
      background: #fff;
      height: 100%;
      width: 46px;
      text-align: center;
      padding-top: 15px;
      border-left: 1px solid $verdun-green;
    } 
  }

  &.active a::before {
    cursor: default;
    content: "";
  }
}

.acc-container .content {
  padding: 2px 15px 5px 15px;
}

.content p {
  margin-bottom: 0;
}
