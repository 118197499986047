
table.standard-table {
  width:100%;
  // table-layout:fixed;
  // word-wrap: break-word;
  border-collapse: separate;
	border-spacing: 0;
	margin-bottom: 15px;
	-webkit-box-shadow:  0px 1px 1px 0px rgba(180, 180, 180, 0.1);
  box-shadow:  0px 1px 1px 0px rgba(180, 180, 180, 0.1);

  @include small-only {
    display: block;
    overflow-x: scroll;
  }

  tr {
    &:last-child {

      td {
        border-bottom: 1px solid $oliver;
      }
    }
  }

  th {
  border-top: 1px solid $oliver;
  border-right: 1px solid $lemon-ginger;
  border-bottom: 1px solid $lemon-ginger;
  background-color: $mint-julep;
  text-align: left;
  padding: 10px 15px;
  color: $verdun-green;
  vertical-align: top;
  font-size: 14px;
  font-weight: bold;

  &:first-child {
    border-left: 1px solid $oliver;
  }

  &:last-child {
    border-right: 1px solid $oliver;
  }
}

  td {
    padding: 10px 15px;
    border-right: 1px solid $lemon-ginger;
    border-bottom: 1px solid $lemon-ginger;

    &:first-child {
      border-left: 1px solid $oliver;
    }

    &:last-child {
      border-right: 1px solid $oliver;
    }
  }

  tr:hover td {
    background-color: #fafafa;
  }
}

