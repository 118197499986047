#footer {
  background: #303030;
  padding: 15px 0;
  margin: 0;

  @media only screen and (max-width: 767px) {
    padding: 15px 0 0 0;
  }

  // @media only screen and (max-width: 1200px) {
  //   margin: 0 auto;
	// 	padding: 15px 0 0 0;
	// 	width: 100% !important;
  // }

  // @media only screen and (min-width: 768px) and (max-width: 990px) {
  //   padding: 10px 0 0 0;
  // } 

  // @media only screen and (max-width: 767px) {
  //   margin: 0 auto;
	// 	padding: 15px 0 0 0;
	// 	width: 100% !important;
  // }

  .footer-inner {
    display: flex;

    @media only screen and (max-width: 767px) {
      display: block;
    }

    .column {
      margin-right: 20px;

      @media only screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 40px;
      }

      &.footer-links {
        flex-grow: 3;

        ul {
          padding-left: 0;
          list-style: none;
          margin-top: 15px;

          li {
            display: inline-block;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

#footer, 
#footer p {
  color: #aaa;
}

#footer a {
  color: #fff;
}

#footer a:hover {
  color: #fff;
}

// .footer-headline {
// 	display: block;
//   margin: 15px 0 8px 0;

  .footer-headline {
    background-color: #303030;
    display: inline;
    padding: 0 10px 0 0;
    color: #fff;
    margin: 15px 0 8px 0;
    display: block;
    font-size: 16px; 
    line-height: 30px; 
    font-weight: 600;
  }
// }

.no-margin {
  margin-top: 0;
}

#footer-bottom {
	border-top: 1px solid #3c3c3c;
	margin-top: 25px;
  padding: 22px 0 25px 0;
  font-size: 12px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 767px) {
    display: block;
  }
  
  a {
    color: #fff;

    &:hover {
      color: #d4d4d4;
    }
  }
}
