@import url("http://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700");
@import url("http://fonts.googleapis.com/css?family=Raleway:500,800");

h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
  color: $orient;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;

  a {
    font-weight: inherit;
  }
}

h1 { 
  color: $olive-tone;
  font-size: 30px; 
  line-height: 30px; 
  font-family: 'Merriweather', serif;
  font-weight: 600;
}

h2 { 
  font-size: 24px; 
  line-height: 30px; 
  color: $blue-lagoon-darker;

}

h3 { 
  font-size: 18px; 
  line-height: 34px; 
}

h4 { 
  font-size: 16px; 
  line-height: 30px; 
}

h5 { 
  font-size: 14px; 
  line-height: 24px; 
}

h6 { 
  font-size: 12px; 
  line-height: 21px; 
}

p { 
  margin: 0 0 15px 0; 
  line-height: 24px;

  img { 
    margin: 0; 
  }

  &.lead { 
    font-size: 21px;
    line-height: 27px; 
    color: #777;  
  }

  a,
  a:visited {
    line-height: inherit;
  }
}

em { 
  font-style: italic; 
}

strong { 
  font-weight: bold; 
  color: #404040; 
}

small { 
  font-size: 80%; 
}

#page-title {
  border-bottom: 1px solid #e9e9e9;
  display: block;
  margin: 0 0 30px 0;

  h2 {
    font-weight: normal;
    padding: 19px 0 35px 0;
    margin: 0;
    font-family: "alverata", serif;
    font-weight: 600;
  }
}

.headline {
  background: none;
  @include large-up {
    // background: url(../images/headline-bg.png) 0 50% repeat-x;
    display: block;
    margin: 30px 0 18px 0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    background-color: #fff;
    display: inline;
    padding: 0 10px 0 0;
    
    span {
      background-color: #fff;
      display: inline;
      padding: 0 10px 0 0;
    }
  }

  &.no-margin {
    margin-top: 0;
  }
}







