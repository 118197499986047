$base-font-size: 100%;
// assets path
$assets: '/Portals/_default/Skins/SC/assets/dist';
// colors 005E6A
$blue-stone: #015b70;
$orient: #005E75;
$blue-lagoon: #10819b;
$blue-lagoon-darker: #00718C;
$java: #40b1cb;


$olive: #5b5610;
$verdun-green: #5B5801;
$oliver: #6C6901;
$olive-tone: #807914;
$olive-light: #8b8708;
$lemon-ginger: #b3af30;
$roti: #B9B53F;

$mint-julep: #f4f3cc;
$citrine-white: #fbfaeb;

// alerts

$error: #9b1001;
$success: #5b5610;
$warning: #894706;
$notice: #1f616f;
// fonts
