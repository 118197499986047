/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';
// Base CSS
@import 'vendor/normalize';
@import 'vendor/tables';
@import 'vendor/forms';

// Vendor
// Additional Presets & Variables
@import 'presets/grid-settings';
@import 'presets/helper-classes';
// Layout
@import 'layout/header';
@import 'layout/content';
@import 'layout/footer';
// Components
@import 'components/buttons';
@import 'components/general';
@import 'components/typography';
@import 'components/branding';
@import 'components/nav-primary';
@import 'components/nav-secondary';
@import 'components/iebar';
@import 'components/slider';
@import 'components/news';
@import 'components/accordions';
@import 'components/tabs';
@import 'components/tables';
@import 'components/alignment';
@import 'components/alert';
@import 'components/tooltip';









