.slider {

  @include large-up {
    height: 730px;
    overflow: hidden;
  }

  img {
    height: 100%;
    width: auto;

    @include xxlarge-up {
      width: 100%;
    }
  }
}

// .cycle-slideshow {
//   z-index: 0;
//   width: 100%;
//   position: relative;
//   @include large-up {
//     height: 560px;
//     overflow: hidden;
//   }

//   img {
//     height: 100%;
//     width: auto;

//     @include xxlarge-up {
//       width: 100%;
//     }
//   }

//   .cycle-prev,
//   .cycle-next {
//     display: none;
//     @include large-up {
//       display: block;
//       opacity: 0;
//       position: absolute;
//       width: 70px;
//       top: 235px;
//       background: rgba(0,0,0,0.3);
//       height: 70px;
//       border-radius: 100px;
//       z-index: 900;
//       text-align: center;
//       font-size: 36px;
//       color: #fff;
//       transition: all 0.8s ease-in-out;
      

//       &.active {
//         opacity: 1;

//         &:hover {
//           background: rgba(0,0,0,0.8);          
//         }
//       }
//     }
//   }

//   .cycle-prev {
//     left: 20px;
//     padding: 9px 11px 9px 9px;
    
//   }

//   .cycle-next {
//     right: 20px;
//     padding: 9px 9px 9px 11px;
    
//   }

// }
