.tabs-nav {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
  width: 100%;
  
  li {
    float: left;
    line-height: 44px;
    overflow: hidden;
    padding: 0;
    position: relative;
    margin: 0 -1px -1px 0;

    &:first-child a {
      border-left: 1px solid $olive-light;
    }

    &:last-child a {
      border-right: 1px solid $olive-light;
    }

    &.active {
      z-index: 9000;
    }

    &.active a {	
      color: $verdun-green; 
      font-weight: bold;
      background: #fff;
      border-bottom: 2px solid #fff;
      border-top: 1px solid $verdun-green;
      border-left: 1px solid $verdun-green;
      border-right: 1px solid $verdun-green;    
      
      i {
        opacity: 1; 
        filter: alpha(opacity=100);
      }
    }

    a {
      background-color: $citrine-white;
      border-right: 1px solid $olive-light;
      border-left: 1px solid $olive-light;
      border-top: 1px solid $olive-light;
      border-bottom: 1px solid $olive;
      color: $oliver;
      display: block;
      letter-spacing: 0;
      outline: none;
      padding: 0 19px;
      text-decoration: none;
      border-collapse: collapse;


      &:hover  {
        background: #fff;
      }
    }

    // i {
    //   opacity: 0.5; 
    //   filter: alpha(opacity=50);
    // }
  }
}

.ie7 .tabs-nav li a {
  height: 35px;
}

.tabs-container {
	border: 1px solid $olive;
	margin: -1px 0 20px;
	overflow: hidden;
	width: 100%;
	-webkit-box-shadow:  0px 1px 1px 0px rgba(180, 180, 180, 0.1);
	box-shadow:  0px 1px 1px 0px rgba(180, 180, 180, 0.1);
  line-height: 23px;
  z-index: 100;
}

.tab-content {padding: 20px;}
