.selectnav {
	display: none;
	cursor: pointer;
	width: 100%;
	padding: 8px;
	height: 37px;
	float: left;
	font-size: 14px;
  margin: 15px 0;
  
  @media only screen and (max-width: 1400px) {
    display: block;    
  }    
}

#navigation {
	background: #303030;
	display: block;
	width: 100%;
	float: left;
	max-height: 52px;
  margin: 0 0 15px 0;
  position: relative;

  @media only screen and (max-width: 767px) {
    float: none;    
  }

  @media only screen and (max-width: 1400px) {
    background: transparent;        
  }   

  ul,
  li {
    list-style:none;
    padding:0;
    margin:0;
    display:inline;
  }

  ul  {
  
    li {
      float:left;
      position:relative;

      a { 
        display: inline-block;
        color: #fff;
        text-decoration: none;
        font-size: 13px;
        font-weight: 600;
        padding: 16px 11px;

        @media only screen and (max-width: 1400px) {
          display: none;        
        }  
        
        @media all and (min-width: 1530px) {
          font-size: 14px;
          font-weight: 600;
          padding: 16px;
        }

        &:hover,
        &:focus, {
          color: #fff;
          background-color: $blue-lagoon;
          -webkit-transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          -ms-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
        }
      }
    }

    ul {
      position: absolute;
      display: none;
      top: 50px;
      left: 0;
      background: #303030;
      z-index: 999;
  
      li { 
        a {
        color: #aaa;
        display: block;
        width: 150px;
        margin: 0;
        padding: 9px 20px;
        font-family: "Open Sans", sans-serif;
        font-weight: normal;
        font-size: 13px;
        border-bottom: 1px solid #444;
        background: none;
  
          &:hover,
          &:focus {
            color: #fff;
          }
        }
      }

      ul { 
        position: absolute; 
        top:0px; 
        left:100%; 
        z-index: 999;
    
        li {
          a { 
            border-bottom: 1px solid #444 !important;
            border-top: 1px solid transparent;
            background: none;
          }
        } 
      }
    }
  }
}

#current {
	background: #888 !important;
	color: #fff !important;
}
