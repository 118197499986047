* {
  box-sizing: border-box;
  // text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}

*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  font-size: 100%;
  margin: 0;
}

body {
  background-color: #e9e9e9;
  font: 14px "Open Sans", sans-serif;
  color: #666;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
 }

.clearfix:after {
  display: table;
  clear: both;
  content: "";
}

a {
  color: $blue-lagoon; 
  text-decoration: none; 
  outline: 0; 
  transition: color 0.1s ease-in-out;
  font-weight: 600;

  &:hover,
  &:focus {
    color: $blue-stone;
    text-decoration: underline;
  }
}

// input {
//   appearance: none;
// }

.element-invisible {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
}

// .clear {
//   zoom: 1;

//   &:before,
//   &:after {
//     display: table;
//     content: "";
//   }

//   &:after {
//     clear: both;
//   }
// }

ul,
ol {
  margin: 0 0 10px 0;
}

ul {

  &.square { 
    list-style: square outside; 
  }

  &.circle { 
    list-style: circle outside; 

  }

  &.disc { 
    list-style: disc outside; 

  }

  &.large li { 
    line-height: 21px; 
  }

  li { 
    line-height: 18px; 
    // list-style: none;

    p { 
      line-height: 24px; 
    }

    // &::before {
    //   // content: '\2022';
    //   content: '\f111';
    //   font-family: FontAwesome;
    //   color: $lemon-ginger;
    //   padding-right: 1em;
    //   margin-left: -2em;
    //   font-size: 8px;
    //   vertical-align: middle;
    // }
  }
  
}

ol {
  list-style: decimal;
}


ul.square, 
ul.circle, 
ul.disc { 
  margin-left: 30px; 
}

blockquote, 
q {
  quotes: none; 

  &:before,
  &:after {
    content: '';
		content: none; 
  }
}

blockquote {
	border-left: 4px solid $lemon-ginger;
	padding-left: 20px;
	color: #888;
	line-height: 20px;
	margin: 5px 0 20px 0;
}

#bolded-line {
  text-indent: -9999px;
  height: 3px;
  width: 100px;
  display: block;
  background: #888;
  margin-bottom: -2px;
}





