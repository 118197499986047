li.news-feature {
  list-style: none;
  margin-bottom: 40px;

  h2 {
    margin-top: 0;
  }
}

.news-header {
  margin-top: 0;
}

.news-listings {

  .post {
    margin-bottom: 50px;

    h2 {
      margin-top: 0;
    }
  }
}

.news-detail {
  h2 {
    margin-top: 0;
  }
}

.news-search {
  display: none;
}