.tooltip {
  position: absolute;
  z-index: 1020;
  display: block;
  padding: 5px;
  font-size: 13px;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: visible;

  &.in {
    opacity: 1;
    filter: alpha(opacity=100);
  }

  &.top {
    margin-top: -2px;

    .tooltip-arrow {
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      border-top: 5px solid #444;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }

  &.right {
    margin-left: 2px;

    .tooltip-arrow {
      top: 50%;
      left: 0;
      margin-top: -5px;
    }
  }

  &.bottom {
    margin-top: 2px;

    .tooltip-arrow {
      top: 0;
      left: 50%;
      margin-left: -5px;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #444;
      border-left: 5px solid transparent;
    }
  }

  .left {
    margin-left: -2px;

    .tooltip-arrow {
      top: 50%;
      right: 0;
      margin-top: -5px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid #444;
    }
  }

}

.tooltip-inner {
  max-width: 200px;
  padding: 4px 10px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #444;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
   border-radius: 2px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  padding: 2px;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}