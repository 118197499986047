.notification {
  font-family: "Open Sans", sans-serif;
  color: #fff;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 15px;
	position: relative;
	padding: 14px 40px 14px 18px;
	-webkit-box-shadow:  0px 1px 1px 0px rgba(180, 180, 180, 0.1);
  box-shadow:  0px 1px 1px 0px rgba(180, 180, 180, 0.1);
  
  p {
    margin: 0;
  }

  span {
    font-weight: bold;
  }

  &.success {
    background-color: $success;
  }

  &.error {
    background-color: $error;
  }

  &.warning {
    background-color: $warning;

  }

  &.notice {
    background-color: $notice;

  }

  &.success,
  &.error,
  &.warning,
  &.notice {
    .close {
      padding: 0px 14px;
      position: absolute;
      right: 0;
      top: 22px;
      display: block;
      height: 8px;
    }
  }

  &.success {
    .close {
      background: url(../images/alert_boxes_close_ico.png) 0 -8px no-repeat;
    }
  }

  &.error {
    .close {
      background: url(../images/alert_boxes_close_ico.png) 0 0 no-repeat;
    }
  }

  &.warning {
    .close {
      background: url(../images/alert_boxes_close_ico.png) 0 -16px no-repeat;
    }
  }

  &.notice {
    .close {
      background: url(../images/alert_boxes_close_ico.png) 0 -24px no-repeat;
    }
  }
  
}
